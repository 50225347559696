












































import { Vue, Component, Prop } from 'vue-property-decorator'
import store, {
  publicGroupsStore,
  uiStore,
} from '@/store'

@Component({
  components: {
    Chat: () => import('../Chat/index.vue'),
    GlobalSearch: () => import('./GlobalSearch/index.vue'),
    BaseSpinner: () => import('@/components/UI/BaseSpinner.vue'),
    IconLogoGray: () => import('@/components/UI/icons/IconLogoGray.vue'),
    IconChatNotSelected: () => import('@/components/UI/icons/IconChatNotSelected.vue'),
    'full-task': () => import('./FullTask/index.vue'),
    'task-desk': () => import('./TaskDesk/index.vue'),
    Meeting: () => import('./Meeting/index.vue'),
  },
})
export default class MiddleColumn extends Vue {
  @Prop({
    required: false,
    type: Boolean,
  }) isChatNotSelected!: boolean

  get showLoading () {
    return publicGroupsStore.state.isLoading
  }

  get isChatExist () {
    return store.getters.currentChat && !!store.getters.chat(store.getters.currentChat)
  }

  get isInstanceDefault () {
    return this.currentInstance === 'chat'
  }

  get currentInstance () {
    return uiStore.getters.currentMiddleColumnInstance
  }

  get showCloseIcon () {
    return this.currentInstance === 'chat'
  }

  get chatErrorMessage () {
    if (store.getters.currentChatErrorType === '403') {
      return this.$t('tasks.errorMessages.403')
    }
    return null
  }

  reset () {
    if (this.currentInstance === 'full-tasks') {
      this.$router.push({
        name: 'Main',
      })
    } else {
      uiStore.actions.resetMiddleColumnInstance()
    }
  }
}
